import Error from 'components/Error/Error';
import LegendPoint from 'assets/legend-point-primary.svg';
import LegendSquare from 'assets/legend-square-tertiary.svg';
import MeasurementChart from 'views/Measurement/MeasurementChart';
import MeasurementGhostLoader from 'views/Measurement/MeasurementGhostLoader';
import React from 'react';
import StatCard from 'components/StatCard/StatCard';
import measurementNewBuyerLiftQuery from 'operations/queries/measurementNewBuyerLift.query';
import useMe from 'hooks/useMe/useMe';
import useSearchParams from 'hooks/useSearchParams';
import { Alert, Card, CardTypes, Grid } from '@klover/attain-design-system';
import {
  MeasurementNewBuyerLiftQuery,
  MeasurementNewBuyerLiftQueryVariables,
  MeasurementOverviewDualAxis,
} from 'types/graphql/generated';
import {
  MeasurementSearchParams,
  dataChart,
} from 'views/Measurement/MeasurementDashboard';
import { getMeasurementNewBuyerLiftInputFromSearchParams } from 'utils/Measurement/measurementInputFromUrlParams';
import { useLazyQuery } from '@apollo/client';
import * as Styled from '../Measurement.styles';

interface Props {
  overviewLoading: boolean;
  impressions: MeasurementOverviewDualAxis | undefined;
  reach: MeasurementOverviewDualAxis | undefined;
  dataChartImpressions: dataChart[];
  dataChartReach: dataChart[];
}

export const MeasurementNewBuyerLift = ({
  overviewLoading,
  impressions,
  reach,
  dataChartImpressions,
  dataChartReach,
}: Props) => {
  const me = useMe();
  const [searchParams] = useSearchParams<MeasurementSearchParams>();
  const [getNewBuyerLiftData, { data, loading }] = useLazyQuery<
    MeasurementNewBuyerLiftQuery,
    MeasurementNewBuyerLiftQueryVariables
  >(measurementNewBuyerLiftQuery, {
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
  });

  React.useEffect(() => {
    window.analytics.page('Page view: Measurement > New buyer lift', {
      ...me,
    });
  }, []);
  React.useEffect(() => {
    const newBuyerLiftInput = getMeasurementNewBuyerLiftInputFromSearchParams(
      searchParams.campaignId,
      searchParams
    );
    if (newBuyerLiftInput) {
      getNewBuyerLiftData({
        variables: {
          input: newBuyerLiftInput,
        },
      });
    }
  }, [searchParams]);

  const metrics =
    data &&
    data.measurementNewBuyerLift.__typename === 'MeasurementNewBuyerLiftSuccess'
      ? data.measurementNewBuyerLift.metrics
      : null;

  const hasData =
    dataChartImpressions &&
    dataChartImpressions.length > 0 &&
    metrics &&
    reach &&
    impressions;

  const hasEnoughUniqueReach = reach && reach.heroMetric.value >= 1000000;

  return !loading && !overviewLoading ? (
    hasData ? (
      <>
        {!hasEnoughUniqueReach && (
          <Grid item xs={24}>
            <Alert severity="warning" title="Unique reach warning">
              The unique reach is below one million. Conversion metrics will not
              be displayed until this campaign reaches one million in unique
              reach.
            </Alert>
          </Grid>
        )}

        <Grid
          item
          xs={12}
          sm={3}
          data-intercom-target="measurement-incremental-conversion-rate"
        >
          <StatCard
            title="NET NEW CVR"
            detail={metrics?.penetraion?.title ?? 'N/A'}
            type={CardTypes.Inverted}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={3}
          data-intercom-target="measurement-incremental-lift"
        >
          <StatCard
            title="NEW BUYER LIFT"
            detail={metrics?.penetrationLift?.title ?? 'N/A'}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={3}
          data-intercom-target="measurement-incremental-roas"
        >
          <StatCard
            title="NEW BUYER ROAS"
            detail={metrics?.roas?.title ?? 'N/A'}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={3}
          data-intercom-target="measurement-incremental-saleslift"
        >
          <StatCard
            title="NEW BUYER SALES DRIVEN"
            detail={metrics?.salesDriven?.title ?? 'N/A'}
            data-intercom-target="measurement-incremental-sales-lift"
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={3}
          data-intercom-target="measurement-incremental-saleslift"
        >
          <StatCard
            title="PROJECTED NEW BUYERS"
            detail={metrics?.projectedNewBuyers?.title ?? 'N/A'}
            data-intercom-target="measurement-incremental-new-buyers"
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={3}
          data-intercom-target="measurement-incrementality-cpa"
        >
          <StatCard
            title="NEW BUYER ACQ. COST (CPA)"
            detail={metrics?.cpa?.title ?? 'N/A'}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          data-intercom-target="measurement-incrementality-avgtransactionamount"
        >
          <StatCard
            title="NEW BUYER AVERAGE TRANSACTION AMOUNT"
            detail={metrics?.avgTransactionAmount?.title ?? 'N/A'}
          />
        </Grid>

        {/* //-- Charts component */}
        <Grid
          item
          xs={12}
          sm={6}
          data-intercom-target="measurement-overview-impressions-new"
        >
          <StatCard
            title="Impressions"
            detail={impressions?.heroMetric?.title ?? 'N/A'}
          >
            {Number(impressions.heroMetric.title) !== 0 && (
              <>
                <MeasurementChart
                  data={dataChartImpressions}
                  label="impressions"
                />

                <Styled.Legend>
                  <div>
                    <Styled.Symbol>
                      <img src={LegendPoint} alt="" />
                    </Styled.Symbol>
                    <span>Running total impressions</span>
                  </div>

                  <div>
                    <Styled.Symbol>
                      <img src={LegendSquare} alt="" />
                    </Styled.Symbol>
                    <span>Daily impressions</span>
                  </div>
                </Styled.Legend>
              </>
            )}
          </StatCard>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          data-intercom-target="measurement-overview-uniquereach-new"
        >
          <StatCard title="Unique Reach" detail={reach?.heroMetric.title}>
            {Number(reach.heroMetric.title) !== 0 && (
              <>
                <MeasurementChart data={dataChartReach} label="unique reach" />

                <Styled.Legend>
                  <div>
                    <Styled.Symbol>
                      <img src={LegendPoint} alt="" />
                    </Styled.Symbol>
                    <span>Running total unique reach</span>
                  </div>

                  <div>
                    <Styled.Symbol>
                      <img src={LegendSquare} alt="" />
                    </Styled.Symbol>
                    <span>Daily unique reach</span>
                  </div>
                </Styled.Legend>
              </>
            )}
          </StatCard>
        </Grid>
      </>
    ) : (
      <Grid item xs={12}>
        <Card>
          <Error message="There has been a problem loading the data for this campaign." />
        </Card>
      </Grid>
    )
  ) : (
    <MeasurementGhostLoader />
  );
};

export default MeasurementNewBuyerLift;
