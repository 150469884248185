import DataTable from 'components/DataTable/DataTable';
import Error from 'components/Error/Error';
import React from 'react';
import VennModule from 'highcharts/modules/venn.js';
import measurementInsightsCompetitorConversionQuery from 'operations/queries/measurementInsightsCompetitorConversion.query';
import { Base64ID } from 'types/models';
import { Card, Grid, theme } from '@klover/attain-design-system';
import { useQuery } from '@apollo/client';
import * as Highcharts from 'highcharts';
import * as HighchartsReact from 'highcharts-react-official';
import * as Styled from './CompetitorConversions.styles';

VennModule(Highcharts);

interface CompetitorConversionsProps {
  campaignId: Base64ID;
  dealIds?: string[];
  attributionWindow?: Base64ID;
  newCustomersWindow?: Base64ID;
  startDate: number;
  endDate: number;
}

export const CompetitorConversions = ({
  campaignId,
  dealIds,
  attributionWindow,
  newCustomersWindow,
  startDate,
  endDate,
}: CompetitorConversionsProps) => {
  const [selectedCompetitor, setSelectedCompetitor] = React.useState<number>(0);
  const { data, loading } = useQuery(
    measurementInsightsCompetitorConversionQuery,
    {
      variables: {
        input: {
          campaignId: campaignId,
          dealIds: dealIds ?? [],
          attributionWindow: attributionWindow,
          newCustomersWindow: newCustomersWindow,
          dateRange: {
            startDate: startDate,
            endDate: endDate,
          },
        },
      },
    }
  );

  const formattedData =
    data && data.measurementInsightsCompetitorConversion.metrics;

  const columns = [
    { title: 'Retailer', sort: 'retailer' },
    { title: 'Net new CVR', sort: 'netNewCVR' },
    { title: 'Avg txn amt', sort: 'avgTransactionAmount' },
    { title: 'Avg purchase freq', sort: 'avgPurchaseFreq' },
    { title: 'Switching %', sort: 'switchingPercent' },
  ];

  const rows =
    formattedData?.map((row, i) => {
      return {
        sortValues: {
          retailer: row.competitor,
          netNewCVR: row.netNewCvr?.value,
          avgTransactionAmount: row.avgTransactionAmtPerTxn?.value,
          avgPurchaseFreq: row.avgTransactionFrequency?.value,
          switchingPercent: row.switchingPercentage?.value,
        },
        component: (
          <>
            <Styled.TableCell
              onClick={() => setSelectedCompetitor(i)}
              $selected={i === selectedCompetitor}
            >
              <Styled.AlignCenter>
                <Styled.Logo src={row.logo} alt="" />

                {row.competitor}
              </Styled.AlignCenter>
            </Styled.TableCell>
            <Styled.TableCell
              onClick={() => setSelectedCompetitor(i)}
              $selected={i === selectedCompetitor}
            >
              {row.netNewCvr?.title}
            </Styled.TableCell>
            <Styled.TableCell
              onClick={() => setSelectedCompetitor(i)}
              $selected={i === selectedCompetitor}
            >
              {row.avgTransactionAmtPerTxn?.title}
            </Styled.TableCell>
            <Styled.TableCell
              onClick={() => setSelectedCompetitor(i)}
              $selected={i === selectedCompetitor}
            >
              {row.avgTransactionFrequency?.title}
            </Styled.TableCell>
            <Styled.TableCell
              onClick={() => setSelectedCompetitor(i)}
              $selected={i === selectedCompetitor}
            >
              {row.switchingPercentage?.title}
            </Styled.TableCell>
            <Styled.TableCell
              onClick={() => setSelectedCompetitor(i)}
              $selected={i === selectedCompetitor}
            ></Styled.TableCell>
          </>
        ),
      };
    }) ?? [];

  const chartComponentRef =
    React.useRef<HighchartsReact.default.RefObject>(null);

  if (
    data &&
    data.measurementInsightsCompetitorConversion &&
    data.measurementInsightsCompetitorConversion.__typename ===
      'MeasurementInsightsCompetitorConversionError'
  ) {
    return (
      <Card>
        <Grid container spacing={theme.spacing.lg}>
          <Grid item xs={12}>
            <Styled.Header>Competitor conversions</Styled.Header>
          </Grid>

          <Grid item xs={12}>
            <Error message="Error loading data" />
          </Grid>
        </Grid>
      </Card>
    );
  }

  const vennData =
    data && data.measurementInsightsCompetitorConversion.venn.values;

  return (
    <Card>
      <Grid container spacing={theme.spacing.lg}>
        <Grid item xs={12}>
          <Styled.Header>Competitor conversions</Styled.Header>
        </Grid>

        <Grid item xs={12} md={7}>
          <Styled.TableWrapper>
            <DataTable
              loading={loading}
              columns={columns}
              rows={rows}
              noBorder
              noFooter
            />
          </Styled.TableWrapper>
        </Grid>

        <Grid item xs={12} md={5}>
          {vennData && vennData[selectedCompetitor] && (
            <Styled.VennWrapper container spacing={theme.spacing.sm}>
              <Styled.Label item xs={6}>
                <Styled.AlignCenter>
                  <Styled.Logo
                    src={formattedData[selectedCompetitor].logo}
                    alt={`${vennData[selectedCompetitor].competitor} logo`}
                  />

                  <div>
                    {vennData[selectedCompetitor].competitor}:{' '}
                    {vennData[selectedCompetitor].competitorConversions.title}
                  </div>
                </Styled.AlignCenter>
              </Styled.Label>

              <Styled.Label item xs={6}>
                <Styled.AlignCenter>
                  <Styled.Logo
                    src={
                      data &&
                      data.measurementInsightsCompetitorConversion.venn
                        .targetBrandLogo
                    }
                    alt={`${data.measurementInsightsCompetitorConversion.venn.targetBrand} logo`}
                  />

                  <div>
                    {
                      data.measurementInsightsCompetitorConversion.venn
                        .targetBrand
                    }
                    : {vennData[selectedCompetitor].targetConversions.title}
                  </div>
                </Styled.AlignCenter>
              </Styled.Label>

              <Grid item xs={12}>
                <HighchartsReact.HighchartsReact
                  highcharts={Highcharts}
                  options={{
                    colors: [
                      'rgba(103, 87, 158, 0.5)',
                      'rgba(34, 221, 211, 0.5)',
                      '#87c1d0',
                    ],
                    chart: {
                      height: '280px',
                    },
                    series: [
                      {
                        type: 'venn',
                        data: [
                          {
                            name: [],
                            sets: ['A'],
                            value:
                              vennData[selectedCompetitor].competitorConversions
                                .value > 0
                                ? vennData[selectedCompetitor]
                                    .competitorConversions.value
                                : 0.00001,
                          },
                          {
                            name: [],
                            sets: ['B'],
                            value:
                              vennData[selectedCompetitor].targetConversions
                                .value > 0
                                ? vennData[selectedCompetitor].targetConversions
                                    .value
                                : 0.00001,
                          },
                          {
                            name: [],
                            sets: ['A', 'B'],
                            value:
                              vennData[selectedCompetitor].overlapConversions
                                .value > 0
                                ? vennData[selectedCompetitor]
                                    .overlapConversions.value
                                : 0.00001,
                          },
                        ],
                        states: {
                          hover: {
                            enabled: false,
                          },
                          inactive: {
                            enabled: false,
                          },
                        },
                        point: {
                          states: {
                            hover: {
                              enabled: false,
                            },
                            inactive: {
                              enabled: false,
                            },
                          },
                        },
                      },
                    ],
                    plotOptions: {
                      venn: {},
                    },
                    tooltip: {
                      enabled: false,
                    },
                  }}
                  ref={chartComponentRef}
                />
              </Grid>

              <Styled.Label item xs={12}>
                Overlap: {vennData[selectedCompetitor].overlapConversions.title}
              </Styled.Label>
            </Styled.VennWrapper>
          )}
        </Grid>
      </Grid>
    </Card>
  );
};

export default CompetitorConversions;
