import DialogCreateSnapshotNew from 'components/DialogCreateSnapshot/DialogCreateSnapshotNew';
import MenuItemExport from 'components/MenuItemExport/MenuItemExport';
import PurchaseTab from './SmartPersonaTabs/PurchaseTab';
import React from 'react';
import Tab from './SmartPersonaTabs/Tab';
import Tabs from 'components/Tabs/Tabs';
import createSnapshotCampaignInsights from 'operations/mutations/createSnapshotCampaignInsights.mutation';
import createSnapshotMutation from 'operations/mutations/createSnapshot.mutation';
import demoNarrationQuery from 'operations/queries/demoNarration.query';
import smartPersonaPurchasesQuery from 'operations/queries/smartPersonaPurchases.query';
import useFeatureFlags from 'hooks/useFeatureFlags/useFeatureFlags';
import useUiFlag from 'hooks/useFeatureFlags/useUiFlag';
import {
  Badge,
  Button,
  IconButton,
  Menu,
  theme,
} from '@klover/attain-design-system';
import { Base64ID } from 'types/models';
import { DotsThreeVertical, FloppyDisk, Scales } from '@phosphor-icons/react';
import {
  MerchantOrBrandType,
  SmartPersona,
  SmartPersonaMeasurementInput,
  SmartPersonaPurchasesInput,
  SmartPersonaPurchasesQuery,
} from 'types/graphql/generated';
import { getUrlParams, pushUrlParam } from 'utils/smartPersonas/urlParameters';
import { hasValidValue } from 'utils/hasValidValue';
import { removeCharsAndSpaces } from 'utils/format';
import { useGlobalState } from 'state';
import { useLazyQuery } from '@apollo/client';
import * as Styled from './SmartPersona.styles';

interface SmartPersonaBodyProps {
  campaign?: SmartPersonaMeasurementInput;
  campaignName?: string;
  demographics: {
    data: SmartPersona[] | null | undefined;
    loading: boolean;
  };
  mediaConsumption: {
    data: SmartPersona[] | null | undefined;
    loading: boolean;
  };
  psychographics: {
    data: SmartPersona[] | null | undefined;
    loading: boolean;
  };
  id?: Base64ID;
  merchantOrBrandNames?: string[] | null;
  merchantOrBrandType?: MerchantOrBrandType | null;
  loading: boolean;
  persona: any;
  personaTitle?: string;
  rightColTitle?: string;
  campaign_id?: Base64ID;
  drawerCategory: string;
  scrollDrawerToTop?: () => any;
}

enum TabsEnum {
  DEMOGRAPHICS = 'Demographics',
  PURCHASE = 'Purchase',
  PSYCHOGRAPHICS = 'Psychographics',
  MEDIACONSUMPTION = 'Media consumption',
}

interface Tab {
  title: any;
  onClick: () => void;
  active: boolean;
}

const SmartPersonaBody = ({
  campaign,
  campaignName,
  demographics,
  psychographics,
  mediaConsumption,
  id,
  loading,
  persona,
  personaTitle,
  rightColTitle,
  campaign_id,
  drawerCategory,
  merchantOrBrandNames,
  merchantOrBrandType,
  scrollDrawerToTop,
}: SmartPersonaBodyProps) => {
  const params = getUrlParams();
  const paramTab = params?.tab ? (params.tab as string) : null;
  const featureflags = useFeatureFlags();
  const [, actions] = useGlobalState();
  const [activeTab, setActiveTab] = React.useState<TabsEnum>(
    paramTab ? TabsEnum[paramTab.toUpperCase()] : TabsEnum.DEMOGRAPHICS
  );
  const [saveSnapshotId, setSaveSnapshotId] = React.useState<Base64ID | null>(
    null
  );
  const [saveCampaignSnapshotId, setSaveCampaignSnapshotId] =
    React.useState<Base64ID | null>(null);
  const [narrationOpen, setNarrationOpen] = React.useState<boolean>(false);
  const [narrationText, setNarrationText] = React.useState<string>();
  const [queryInput, setQueryInput] =
    React.useState<SmartPersonaPurchasesInput>();

  const [fetchPurchases, { data: purchasesData, loading: purchasesLoading }] =
    useLazyQuery<SmartPersonaPurchasesQuery>(smartPersonaPurchasesQuery, {
      fetchPolicy: 'no-cache',
      variables: {
        input: queryInput,
      },
    });

  React.useEffect(() => {
    if (campaign) {
      setQueryInput({ campaign: campaign });
      void fetchPurchases();
    }
    if (id) {
      setQueryInput({ id: id });
      void fetchPurchases();
    }
  }, [campaign, id, setQueryInput]);

  const [fetchNarration, { loading: narrationLoading, called }] = useLazyQuery(
    demoNarrationQuery,
    {
      variables: {
        input: {
          campaignId: campaign_id,
        },
      },
      onCompleted: (res) => {
        const rollText = (text) => {
          setNarrationText((existing) => (existing || '') + text[0] + ' ');
          if (text.length > 1) {
            return setTimeout(() => {
              rollText(text.slice(1));
            }, 50);
          }
        };
        rollText(res?.demoNarration?.message?.split(' '));
      },
    }
  );

  // expand / collapse
  const [expandedAccordions, setExpandedAccordions] = React.useState<number[]>(
    []
  );
  const accordionClicked = (index: number) => {
    if (expandedAccordions.includes(index))
      setExpandedAccordions(
        expandedAccordions.filter((number) => number !== index)
      );
    else setExpandedAccordions([...expandedAccordions, index]);
  };

  const collapseAll = () => {
    setExpandedAccordions([]);
  };

  const expandAll = () => {
    const newArray: number[] = [];
    for (let i = 0; i < 50; i++) {
      newArray.push(i);
    }
    setExpandedAccordions(newArray);
  };

  React.useEffect(() => {
    if (demographics.data && demographics.data.length > 0) {
      expandAll();
    }
  }, [demographics]);

  const handleDemoNarration = React.useCallback(() => {
    if (narrationOpen) {
      setNarrationOpen(false);
    } else {
      setNarrationOpen(true);
      if (!called) {
        void fetchNarration();
      }
    }
  }, [fetchNarration, setNarrationOpen, narrationOpen, called]);

  const handleTabChange = (tabKey: string) => {
    pushUrlParam('tab', tabKey.toLowerCase());
    setActiveTab(TabsEnum[tabKey]);
  };

  React.useEffect(() => {
    if (activeTab === TabsEnum.PURCHASE) {
      collapseAll();
    } else {
      expandAll();
    }
  }, [activeTab]);

  const tabs = React.useMemo(() => {
    const excludeMap = new Map();
    const tabs: Tab[] = [];

    //--Set the tabs to be excluded.
    if (drawerCategory === 'Converter Insights') {
      //measurement personas
      !featureflags.flags['MEASUREMENT_MEDIA_CONSUMPTION'] &&
        excludeMap.set(TabsEnum.MEDIACONSUMPTION, 1);
      !featureflags.flags['MEASUREMENT_PSYCHOGRAPHICS'] &&
        excludeMap.set(TabsEnum.PSYCHOGRAPHICS, 1);
      !featureflags.flags['MEASUREMENT_PSYCHOGRAPHICS'] &&
        excludeMap.set(TabsEnum.PSYCHOGRAPHICS, 1);
      !featureflags.flags['MEASUREMENT_PURCHASE_INSIGHTS'] &&
        excludeMap.set(TabsEnum.PURCHASE, 1);
    } else {
      // non measurement personas
      !featureflags.flags['SMART_PERSONA_PSYCHOGRAPHICS'] &&
        excludeMap.set(TabsEnum.PSYCHOGRAPHICS, 1);
      !featureflags.flags['SMART_PERSONA_MEDIA_CONSUMPTION'] &&
        excludeMap.set(TabsEnum.MEDIACONSUMPTION, 1);
      !featureflags.flags['SMART_PERSONA_PURCHASE_INSIGHTS'] &&
        excludeMap.set(TabsEnum.PURCHASE, 1);
    }

    // -- set tabs.
    for (const key of Object.keys(TabsEnum)) {
      if (!excludeMap?.has(TabsEnum[key])) {
        tabs.push({
          title: TabsEnum[key],
          onClick: () => handleTabChange(key),
          active: activeTab === TabsEnum[key],
        });
      }
    }

    return tabs;
  }, [featureflags.flags, drawerCategory, activeTab]);

  const drawerTitle = loading
    ? 'Loading...'
    : personaTitle
    ? personaTitle
    : persona?.title;

  function createExportRows(data, category) {
    return (data ?? [])
      .map((bucket) => {
        return bucket.data.map((segment) => [
          category,
          bucket.title,
          segment.title,
          segment.index.title,
          segment.index.description,
        ]);
      })
      .flat();
  }

  const demographicsExportRows = createExportRows(
    demographics.data,
    'Demographics'
  );
  const psychographicsExportRows = createExportRows(
    psychographics.data,
    'Psychographics'
  );
  const mediaConsumptionExportRows = createExportRows(
    mediaConsumption.data,
    'Media Consumption'
  );

  const title = `Attain ${drawerCategory}, ${
    campaignName ? `${campaignName}, ` : ''
  }${drawerTitle}`;

  const exportData = [
    [title],
    [new Date()],
    [window.location.href],
    [
      'This includes demographic, pyschographic, and media consumption data on the selected audience.',
    ],
    [''],
    [''],
    ['Type', 'Bucket', 'Segment', 'Index vs. all shoppers', 'Score'],
    ...demographicsExportRows,
    ...psychographicsExportRows,
    ...mediaConsumptionExportRows,
  ];

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const censusBalancing = useUiFlag('ui_census_balancing');
  const allowCB = censusBalancing.isReady && censusBalancing.enabled;

  const hasDemographicsAudiencePercentage =
    demographics &&
    activeTab === TabsEnum.DEMOGRAPHICS &&
    hasValidValue(demographics, 'percentOfAudience');

  const hasPurchasesAudiencePercentage =
    purchasesData &&
    activeTab === TabsEnum.PURCHASE &&
    hasValidValue(purchasesData, 'audiencePercentage');

  return (
    <>
      <Styled.DrawerHeader>
        <Styled.DrawerActions>
          <Styled.DrawerGrow>
            <Styled.Close
              size={theme.icons.lg}
              onClick={() => {
                actions.hideDrawer();
              }}
            />
          </Styled.DrawerGrow>

          {!loading && (
            <>
              {(id || campaign_id) && (
                <Button
                  startIcon={<FloppyDisk size={theme.icons.lg} />}
                  onClick={() => {
                    if (id) {
                      setSaveSnapshotId(id);
                    }
                    if (campaign_id) {
                      setSaveCampaignSnapshotId(campaign_id);
                    }
                  }}
                >
                  Save
                </Button>
              )}

              <div>
                <IconButton
                  id="basic-button"
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick}
                >
                  <DotsThreeVertical size={theme.icons.lg} />
                </IconButton>

                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                  transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                >
                  <MenuItemExport
                    data={exportData}
                    filename={`${removeCharsAndSpaces(title)}.csv`}
                    key="export"
                  />
                </Menu>
              </div>
            </>
          )}
        </Styled.DrawerActions>

        <Styled.DrawerTitleWrapper>
          <div>
            <Styled.DrawerCategory>{drawerCategory}</Styled.DrawerCategory>

            <Styled.DrawerTitle>{drawerTitle}</Styled.DrawerTitle>
            <Styled.DrawerBadges>
              {merchantOrBrandType &&
                merchantOrBrandNames &&
                merchantOrBrandType !== MerchantOrBrandType.MERCHANT && (
                  <>
                    <Badge color="brandPrimary">
                      {merchantOrBrandType.charAt(0).toUpperCase() +
                        merchantOrBrandType.slice(1).toLowerCase()}
                    </Badge>

                    {merchantOrBrandNames.map((item, i) => (
                      <Badge key={i}>{item}</Badge>
                    ))}
                  </>
                )}

              {allowCB && (
                <>
                  {(hasDemographicsAudiencePercentage ||
                    hasPurchasesAudiencePercentage) && (
                    <Styled.CensusBalanced>
                      <Scales size={theme.icons.md} />
                      Census balanced
                    </Styled.CensusBalanced>
                  )}
                </>
              )}
            </Styled.DrawerBadges>
          </div>

          {campaign_id && featureflags.flags?.['DEMOGRAPHIC_NARRATION'] && (
            <Button onClick={handleDemoNarration}>Narration</Button>
          )}
        </Styled.DrawerTitleWrapper>

        <div>
          <Tabs links={false} tabs={tabs} />
        </div>
      </Styled.DrawerHeader>

      <Styled.DrawerContent>
        {activeTab !== TabsEnum.PURCHASE && (
          <Styled.Search>
            <Styled.TabTitle>{activeTab}</Styled.TabTitle>

            <Button
              loading={loading}
              onClick={() =>
                expandedAccordions.length <= 0 ? expandAll() : collapseAll()
              }
              variant="contained"
            >
              <span style={{ whiteSpace: 'nowrap' }}>
                {expandedAccordions.length <= 0 ? 'Expand all' : 'Collapse all'}
              </span>
            </Button>
          </Styled.Search>
        )}

        <>
          {narrationOpen ? (
            <Styled.NarrationWrapper>
              {narrationLoading ? (
                'Loading...'
              ) : (
                <Styled.NarrationBox>
                  <div
                    dangerouslySetInnerHTML={{ __html: narrationText || '' }}
                  />
                </Styled.NarrationBox>
              )}
            </Styled.NarrationWrapper>
          ) : (
            <>
              {activeTab === TabsEnum.DEMOGRAPHICS && (
                <Tab
                  segments={demographics}
                  accordionClicked={accordionClicked}
                  expandedAccordions={expandedAccordions}
                  rightColTitle={rightColTitle}
                />
              )}
              {activeTab === TabsEnum.PURCHASE && (
                <PurchaseTab
                  data={purchasesData}
                  loading={purchasesLoading}
                  scrollDrawerToTop={scrollDrawerToTop}
                />
              )}
              {activeTab === TabsEnum.PSYCHOGRAPHICS && (
                <Tab
                  segments={psychographics}
                  accordionClicked={accordionClicked}
                  expandedAccordions={expandedAccordions}
                  rightColTitle={rightColTitle}
                  showComingSoon
                />
              )}
              {activeTab === TabsEnum.MEDIACONSUMPTION && (
                <Tab
                  segments={mediaConsumption}
                  accordionClicked={accordionClicked}
                  expandedAccordions={expandedAccordions}
                  rightColTitle={rightColTitle}
                  showComingSoon
                />
              )}
            </>
          )}
        </>
      </Styled.DrawerContent>

      {id && (
        <DialogCreateSnapshotNew
          isOpen={!!saveSnapshotId}
          mutation={createSnapshotMutation}
          mutationInput={{
            filters: [],
            id: saveSnapshotId,
          }}
          onClose={() => setSaveSnapshotId(null)}
        />
      )}

      {campaign_id && (
        <DialogCreateSnapshotNew
          isOpen={!!saveCampaignSnapshotId}
          mutation={createSnapshotCampaignInsights}
          mutationInput={{ ...campaign }}
          onClose={() => setSaveCampaignSnapshotId(null)}
        />
      )}
    </>
  );
};

export default SmartPersonaBody;
