import ComingSoonBadge from 'components/ComingSoonBadge/ComingSoonBadge';
import DataTable from 'components/DataTable/DataTable';
import Error from 'components/Error/Error';
import MetricBar100 from 'components/MetricBar100/MetricBar100';
import React from 'react';
import useSearchParams from 'hooks/useSearchParams';
import { Card } from '@klover/attain-design-system';
import { DEFAULT_MEASUREMENT_ATTRIBUTION_WINDOW_ID } from 'utils/Measurement/measurementPaths';
import {
  MeasurementInsightsPaymentChannelBreakdown,
  MeasurementInsightsPaymentChannelQuery,
  MeasurementInsightsPaymentChannelQueryVariables,
  MeasurementOverviewCustomerType,
} from 'types/graphql/generated';
import { MeasurementSearchParams } from 'views/Measurement/MeasurementDashboard';
import { findHighestValue } from 'utils/findHighestValue';
import { measurementInsightsPaymentChannelQuery } from 'operations/queries/measurementInsightsPaymentChannel.query';
import { useQuery } from '@apollo/client';

export const PaymentChannels = () => {
  const [searchParams] = useSearchParams<MeasurementSearchParams>();

  const variables: MeasurementInsightsPaymentChannelQueryVariables =
    React.useMemo(
      () => ({
        input: {
          campaignId: searchParams.campaignId,
          dealIds: searchParams.deals || [],
          attributionWindow:
            searchParams.attributionwindow ??
            DEFAULT_MEASUREMENT_ATTRIBUTION_WINDOW_ID,
          customerType:
            searchParams.customertype ??
            MeasurementOverviewCustomerType.ALL_CUSTOMERS,
          dateRange: {
            startDate: searchParams.datefrom
              ? Number(searchParams.datefrom)
              : 0,
            endDate: searchParams.dateto
              ? Number(searchParams.dateto)
              : Math.floor(Date.now() / 1000),
          },
          newCustomersWindow:
            searchParams.customertype ===
            MeasurementOverviewCustomerType.NET_NEW_CUSTOMERS
              ? searchParams.newcustomerwindow
              : undefined,
        },
      }),
      [searchParams]
    );

  const { data, loading, error } = useQuery<
    MeasurementInsightsPaymentChannelQuery,
    MeasurementInsightsPaymentChannelQueryVariables
  >(measurementInsightsPaymentChannelQuery, { variables });

  const rows = data?.measurementInsightsPaymentChannel.paymentChannels || [];
  const highestPercentSpend = findHighestValue(rows, 'percentSpend');
  const highestPercentTransactions = findHighestValue(
    rows,
    'percentTransactions'
  );

  const columns = React.useMemo(
    () => [
      { title: 'Purchase made', sort: 'paymentChannel', width: '25%' },
      { title: '% of spend', sort: 'percentSpendValue', width: '25%' },
      { title: '% of trips', sort: 'percentTransactionsValue', width: '25%' },
      {
        title: (
          <>
            ROAS
            <ComingSoonBadge />
          </>
        ),
        sort: 'roasValue',
        width: '25%',
      },
    ],
    []
  );

  const formattedRows = rows?.map(
    (row: MeasurementInsightsPaymentChannelBreakdown, i) => ({
      sortValues: {
        paymentChannel: row.paymentChannel,
        percentSpendValue: row.percentSpend?.value,
        percentTransactionsValue: row.percentTransactions?.value,
      },
      component: (
        <React.Fragment key={i}>
          <td>{row.paymentChannel}</td>
          <td>
            <MetricBar100
              index={row.percentSpend ?? undefined}
              highestIndex={highestPercentSpend}
            />
          </td>
          <td>
            <MetricBar100
              index={row.percentTransactions ?? undefined}
              highestIndex={highestPercentTransactions}
            />
          </td>
          <td>--</td>
        </React.Fragment>
      ),
    })
  );

  if (error) {
    return (
      <Card>
        <Error message="Error loading online vs. In store" />
      </Card>
    );
  }

  return (
    <DataTable
      title="Online vs. In store"
      description="Online vs. In store"
      columns={columns}
      rows={formattedRows}
      loading={loading}
      noFooter
    />
  );
};

export default PaymentChannels;
