import StatCard, { StatCardLoading } from 'components/StatCard/StatCard';
import measurementInsightsConversionTimeQuery from 'operations/queries/MeasurementInsightsConversionTime.query';
import { Alert } from '@klover/attain-design-system';
import { ResponsiveBar } from '@nivo/bar';
import { useQuery } from '@apollo/client';
import * as Styled from './TimeToConversion.styles';

export const TimeToConversion = ({
  campaignId,
  newCustomersWindow,
  dealIds,
}) => {
  const { data, loading, error } = useQuery(
    measurementInsightsConversionTimeQuery,
    {
      variables: {
        input: {
          campaignId: campaignId,
          dealIds: dealIds,
          newCustomersWindow: newCustomersWindow,
        },
      },
    }
  );

  if (loading) {
    return <StatCardLoading data-testid="statcard-loading" />;
  }

  if (
    data.measurementInsightsConversionTime.__typename ===
      'MeasurementInsightsConversionTimeError' ||
    error
  ) {
    return (
      <StatCard title="Time to Conversion">
        <Alert severity="error">Error loading data</Alert>
      </StatCard>
    );
  }

  const metrics = data.measurementInsightsConversionTime.metrics;

  const formattedData =
    metrics && metrics.length > 0
      ? metrics.map((row) => ({
          x: row.timeToConvert.value,
          y: row.percentageOfUsers.value,
        }))
      : null;

  const maxYEntry =
    formattedData && formattedData.length > 0
      ? formattedData.reduce(
          (max, row) => (row.y > max.y ? row : max),
          formattedData[0]
        )
      : null;

  const maxXValue = maxYEntry ? maxYEntry.x : null;

  return (
    <StatCard
      title="Time to Conversion"
      detail={maxYEntry && maxYEntry.y > 0 ? maxXValue + ' days' : null}
    >
      {formattedData &&
      formattedData.length > 1 &&
      maxYEntry &&
      maxYEntry.y > 0 ? (
        <Styled.ChartWrapper data-testid="chart">
          <Styled.YTitle>% of users</Styled.YTitle>
          <Styled.XTitle>Days to conversion</Styled.XTitle>

          <ResponsiveBar
            data={formattedData}
            keys={['y']}
            indexBy="x"
            colors={['#67579E']}
            margin={{ top: 31, right: 0, bottom: 48, left: 36 }}
            padding={0.5}
            label={() => ''}
            tooltip={(props) => (
              <Styled.ChartTooltip>
                {props.indexValue + ' days'} - {(props.value * 100).toFixed(2)}%
              </Styled.ChartTooltip>
            )}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 0,
              tickPadding: 8,
            }}
            axisLeft={{
              tickSize: 0,
              tickPadding: 8,
              format: (value) => `${(value * 100).toFixed(0)}%`,
            }}
          />
        </Styled.ChartWrapper>
      ) : (
        <Alert severity="info">Not enough data for this chart</Alert>
      )}
    </StatCard>
  );
};

export default TimeToConversion;
