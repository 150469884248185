import { gql } from '@apollo/client';

export const incrementalityQuery = gql`
  query Incrementality(
    $campaignId: Base64ID!
    $postPeriod: Int!
    $input: IncrementalityInput
  ) {
    incrementality(
      campaignId: $campaignId
      postPeriod: $postPeriod
      input: $input
    ) {
      ... on IncrementalitySuccess {
        incrementalRoas {
          title
          value
        }
        incrementalRoasSignificance
        incrementalSalesDriven {
          value
          title
        }
        incrementalSalesDrivenSignificance
        incrementalityBreakdown {
          transactionLift {
            value {
              title
              value
            }
            significance
          }
          title
          salesLift {
            value {
              title
              value
            }
            significance
          }
        }
        salesLift {
          significance
          value {
            value
            title
          }
        }
        salesLiftTrendedChart {
          date
          liftLowerBound {
            value
            title
          }
          liftUpperBound {
            value
            title
          }
          lift {
            value {
              title
              value
            }
            significance
          }
        }
        transactionLift {
          significance
          value {
            title
            value
          }
        }
        transactionLiftTrendedChart {
          date
          liftLowerBound {
            value
            title
          }
          liftUpperBound {
            value
            title
          }
          lift {
            value {
              title
              value
            }
            significance
          }
        }
      }
      ... on IncrementalityError {
        message
      }
    }
  }
`;

export default incrementalityQuery;
