import styled from 'styled-components';
import { Table, theme } from '@klover/attain-design-system';

export const Wrapper = styled.div<{ $noBorder: boolean }>`
  ${({ $noBorder }) => !$noBorder && theme.mixins.card}
  overflow: ${({ $noBorder }) => !$noBorder && 'hidden'};
`;
export const HeaderWrapper = styled.div`
  padding-block-end: ${theme.spacing.lg};
`;

export const CardHeader = styled.header`
  align-items: center;
  display: flex;
  gap: ${theme.spacing.xxs};
`;

export const CardTitle = styled.h3`
  ${theme.typography.headingSM};
  flex-grow: 1;
`;

export const CardDesc = styled.p`
  ${theme.typography.bodyLG};
  color: ${theme.colors.textTertiary};
  margin-block: ${theme.spacing.xxs} 0;
`;

export const TableWrapper = styled(Table)<{ $noBorder: boolean }>`
  margin: ${({ $noBorder }) =>
    $noBorder
      ? `0 0 -${theme.spacing.lg}`
      : `0 -${theme.spacing.lg} -${theme.spacing.lg}`};
  width: ${({ $noBorder }) =>
    $noBorder ? `100%` : `calc(100% + 2 * ${theme.spacing.lg})`};

  th,
  td {
    border-block-start: none;
    padding: 0 ${theme.spacing.xs};

    &:first-child {
      padding-inline-start: ${theme.spacing.lg};
    }

    &:last-child {
      padding-inline: 0 ${theme.spacing.md};
    }
  }

  th {
    cursor: pointer;
    padding-block: 0 ${theme.spacing.xs};

    &:not(:first-child) {
      text-align: center;
    }
  }

  td {
    height: 64px;
    text-align: center;

    &:first-child {
      ${theme.typography.bodyLG};
      text-align: start;
    }
  }
`;

export const Row = styled.tr`
  position: relative;
`;
