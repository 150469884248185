import AttributionWindowSelect from '../AttributionWindow/AttributionWindowsSelect';
import DatepickerRange from 'components/Datepicker/DatePickerRange/DatepickerRange';
import DealSelectAutocomplete from '../DealSelect/DealSelectAutocomplete';
import Filter from '../Filter/Filter';
import PostPeriodSelect from '../PostPeriodSlider/PostPeriodSlider';
import React from 'react';
import paths from 'constants/paths';
import useUiFlag from 'hooks/useFeatureFlags/useUiFlag';
import {
  Button,
  RadioGroup,
  Switch,
  theme,
} from '@klover/attain-design-system';
import { DateRange } from 'react-day-picker';
import {
  Deal,
  MeasurementOverviewOptionsSuccess,
} from 'types/graphql/generated';
import { MeasurementForm } from 'hooks/useMeasurementForm/useMeasurementForm';
import { dateFormat } from 'highcharts';
import * as Styled from '../MeasurementFilters.styles';

interface Props {
  form: MeasurementForm;
  deals?: Deal[] | null;
  windows?: MeasurementOverviewOptionsSuccess | null;
  dateLimit?: DateRange | null;
  handleApplyFilters: () => void;
  handleResetFilters: () => void;
  onClose: () => void;
  open: boolean;
  decimalPlaces: string;
  setDecimalPlaces: React.Dispatch<React.SetStateAction<string>>;
}

export const MeasurementFilters = ({
  form,
  deals,
  windows,
  dateLimit,
  handleApplyFilters,
  handleResetFilters,
  onClose,
  open,
  decimalPlaces,
  setDecimalPlaces,
}: Props) => {
  const allowInsightsMeasurement = useUiFlag('ui_measurement_insights');
  const showInsightsMeasurement =
    allowInsightsMeasurement.isReady && allowInsightsMeasurement.enabled;
  const allowImputeToggle = useUiFlag('ui_incrementality_impute_toggle');
  const campaignType = form.getValues('campaign.__typename');
  const isIncrementality =
    window.location.pathname ===
    paths.MEASUREMENT_DASHBOARD + paths.MEASUREMENT_DASHBOARD_INCREMENTALITY;
  const isOptimizations =
    window.location.pathname ===
    paths.MEASUREMENT_DASHBOARD + paths.MEASUREMENT_DASHBOARD_OPTIMIZATION;
  const isNewBuyerLift =
    window.location.pathname ===
    paths.MEASUREMENT_DASHBOARD + paths.MEASUREMENT_DASHBOARD_NEW_BUYER_LIFT;

  const showImputeToggle =
    isIncrementality &&
    allowImputeToggle.isReady &&
    allowImputeToggle.enabled &&
    form.getValues('campaign.merchantsOrBrands')?.at(0)?.__typename ===
      'CampaignBrand';

  const isOpenX = campaignType === 'OpenXCampaign';

  return (
    <Styled.DrawerWrapper open={open} onClose={onClose} anchor="right">
      <Styled.Wrapper>
        <Styled.Main>
          <Styled.Header>
            <Styled.Title>Filters</Styled.Title>
            <Styled.Close size={theme.icons.lg} onClick={onClose} />
          </Styled.Header>

          {isOpenX && (
            <Filter
              title="Deals"
              badgeText={`${form.getValues('deals')?.length ?? '0'} selected`}
              defaultExpanded
            >
              {/* <FormHelperText>Select deals</FormHelperText> */}
              <DealSelectAutocomplete
                deals={(deals as Deal[]) || []}
                selected={(form.watch('deals') as Deal[]) || []}
                setter={(value: Deal[]) => {
                  form.setValue('deals', value);
                }}
                loading={false}
                disabled={false}
              />
            </Filter>
          )}
          {!isIncrementality && (
            <Filter
              title="Date range"
              badgeText={`${dateFormat(
                '%b %e',
                form.getValues('dateRange.from')?.getTime() || 0,
                true
              )} - ${dateFormat(
                '%b %e',
                form.getValues('dateRange.to')?.getTime() ||
                  new Date().getTime(),
                true
              )}`}
              defaultExpanded
            >
              <DatepickerRange
                alignLeft
                disclaimer={
                  isOpenX ? 'Impressions are on a three day lag' : undefined
                }
                inputLabel=""
                dateLimit={dateLimit || undefined}
                onSelect={(value: DateRange | undefined) => {
                  form.setValue('dateRange', value);
                }}
                selected={form.watch('dateRange') || undefined}
              />
            </Filter>
          )}

          {showInsightsMeasurement &&
            (isIncrementality ? (
              <Filter
                title="Post period"
                badgeText={`${form.getValues('postPeriod')} days`}
                defaultExpanded
              >
                <PostPeriodSelect
                  max={45}
                  value={form.getValues('postPeriod') ?? 0}
                  onChange={(val: number) => {
                    form.setValue('postPeriod', val);
                  }}
                  loading={false}
                />
              </Filter>
            ) : (
              <Filter
                title="Attribution window"
                badgeText={`${form.getValues('window.title')}`}
                defaultExpanded
              >
                <AttributionWindowSelect
                  disabled={!windows?.attributionWindows}
                  attributionWindows={windows?.attributionWindows}
                  value={form.getValues('window') ?? null}
                  onChange={(value) => {
                    form.setValue('window', value);
                  }}
                />
              </Filter>
            ))}

          {isNewBuyerLift && (
            <Filter
              title="Net new buyer window"
              badgeText={form.getValues('newCustomerWindow.title')}
              defaultExpanded
            >
              <RadioGroup
                items={
                  windows?.newCustomerWindows.map((item) => item.title) || []
                }
                value={form.getValues('newCustomerWindow.title') || null}
                onChange={(_, value: string) => {
                  form.setValue(
                    'newCustomerWindow',
                    windows?.newCustomerWindows.find(
                      (item) => item.title === value
                    )
                  );
                }}
              />
            </Filter>
          )}

          {isOptimizations && (
            <Filter
              title="Number of decimal places"
              badgeText={decimalPlaces + ' decimal places'}
              defaultExpanded
            >
              <p>Select one. This will impact the Optimizations tab only.</p>
              <RadioGroup
                items={['0', '1', '2', '3', '4']}
                value={decimalPlaces}
                onChange={(_, value: string) => {
                  setDecimalPlaces(value);
                }}
              />
            </Filter>
          )}

          {showImputeToggle && (
            <Filter
              title="Use imputed data"
              badgeText={form.getValues('useImpute') ? 'On' : 'Off'}
              defaultExpanded
            >
              off
              <Switch
                checked={form.getValues('useImpute')}
                onChange={(_, checked) => {
                  form.setValue('useImpute', checked);
                }}
              />
              on
            </Filter>
          )}
        </Styled.Main>

        <Styled.Footer>
          <Button variant="contained" onClick={handleApplyFilters}>
            Apply filters
          </Button>
          <Button variant="outlined" onClick={handleResetFilters}>
            Reset filters
          </Button>
        </Styled.Footer>
      </Styled.Wrapper>
    </Styled.DrawerWrapper>
  );
};

export default MeasurementFilters;
