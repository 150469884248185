import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { theme } from '@klover/attain-design-system';

export const Wrapper = styled.div<{ $open: boolean; children: any }>`
  background: ${theme.colors.backgroundBrand};
  color: ${theme.colors.textInverted};
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
  opacity: ${({ $open }) => ($open ? '1' : '0')};
  transform: ${({ $open }) => ($open ? 'none' : 'translateX(-100%)')};
  transition: opacity 0.25s ease, transform 0.25s ease;
  z-index: 9999999;

  @media ${theme.mq.tabletPortraitUp} {
    opacity: 1;
    overflow: hidden;
    overscroll-behavior-y: none;
    transform: none;
    transition: none;
    z-index: 0;
  }
`;

export const List = styled.ul`
  && {
    margin: ${theme.spacing.md} ${theme.spacing.sm};
  }
`;

export const ListLink = styled(NavLink)`
  ${theme.typography.bodyMD}
  align-items: center;
  border-radius: ${theme.spacing.xs};
  color: ${theme.colors.textInverted};
  cursor: pointer;
  display: flex;
  gap: ${theme.spacing.md};
  padding: ${theme.spacing.sm};
  text-decoration: none;

  &:hover {
    background: ${theme.colors.buttonBackgroundHover};
  }

  &.active {
    background: ${theme.colors.buttonBackgroundHover};
    cursor: default;
  }
`;

export const Label = styled.div`
  flex-grow: 1;
`;

export const SubList = styled.ul``;

export const SubListLink = styled(ListLink)`
  ${theme.typography.bodySM}
  border-left: solid 3px ${theme.colors.buttonBackgroundHover};
  margin-left: calc(22px + ${theme.spacing.md});

  &.active {
    border-left: solid 2px ${theme.colors.backgroundPrimary};
  }
`;
