import styled from 'styled-components';

import { theme } from '@klover/attain-design-system';

interface TitleProps {
  $isInverted?: boolean;
  $uppercase?: boolean;
  children?: any;
}

export const Title = styled.div<TitleProps>`
  ${theme.typography.bodyMD};
  color: ${({ $isInverted }) =>
    $isInverted ? theme.colors.textInverted : theme.colors.textTertiary};
  text-transform: uppercase;
`;

export const Detail = styled.div`
  ${theme.typography.headingLG};
  margin-top: ${theme.spacing.xs};
`;

export const Content = styled.div`
  margin-top: ${theme.spacing.xl};
`;
