import FallbackPageLoad from 'components/FallbackLoader/FallbackPageLoad';
import LoginRedirect from 'views/LoginRedirect';
import MeasurementInsightsDashboard from 'views/Measurement/MeasurementInsightsDashboard';
import React, { Suspense } from 'react';
import VerifyEmail from 'views/VerifyEmail';
import _403 from 'views/403';
import _404 from 'views/404';
import paths from './constants/paths';
import trackEvent from 'utils/trackingEvents';
import useFeatureFlags from 'hooks/useFeatureFlags/useFeatureFlags';
import useMe from 'hooks/useMe/useMe';
import useUiFlag from 'hooks/useFeatureFlags/useUiFlag';
import whereToGoQuery from 'operations/queries/whereToGo.query';
import { AuthRequired } from '@klover/ksaas-common-web/services/AuthRequired';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { WhereToGoQuery } from 'types/graphql/generated';
import { useAuth0 } from '@auth0/auth0-react';
import { useIntercom } from 'react-use-intercom';
import { useQuery } from '@apollo/client';

const Home = React.lazy(() => import('./views/Home'));
const Audiences = React.lazy(() => import('views/Audiences/Audiences'));
const BuildAudience = React.lazy(
  () => import('views/Audiences/AudienceBuild/AudienceBuild')
);
const Map = React.lazy(() => import('./views/ActivityMap/ActivityMap'));
const MapDove = React.lazy(() => import('./views/ActivityMap/ActivityMapV2'));
const Insights = React.lazy(() => import('views/Insights/Insights'));
const InsightsBrands = React.lazy(
  () => import('views/InsightsNew/InsightsBrands')
);
const InsightsMerchants = React.lazy(
  () => import('views/InsightsNew/InsightsMerchants')
);
const Affinities = React.lazy(
  () => import('views/Insights/Affinities/Affinities')
);
const Conquesting = React.lazy(
  () => import('views/Insights/Conquesting/Conquesting')
);
const ConsumerProfile = React.lazy(
  () => import('views/Insights/ConsumerProfile/ConsumerProfile')
);
const CrossPurchase = React.lazy(
  () => import('views/Insights/CrossPurchases/CrossPurchase')
);
const RFM = React.lazy(() => import('views/Insights/RFM/RFM'));
const Switching = React.lazy(
  () => import('views/Insights/Switching/Switching')
);
const Permissions = React.lazy(() => import('views/Outcome/Permissions'));
const Campaign = React.lazy(() => import('views/Outcome/Campaign'));
const Measurement = React.lazy(() => import('views/Measurement'));
const MeasurementDashboard = React.lazy(
  () => import('views/Measurement/MeasurementDashboard')
);
const Onboarding = React.lazy(() => import('views/Onboarding'));
const Outcome = React.lazy(() => import('views/Outcome'));

export const InternalApp = () => {
  const me = useMe();
  const { user } = useAuth0();
  const { boot } = useIntercom();
  const featureflags = useFeatureFlags();
  const outcomeAllowed =
    featureflags.isReady && featureflags.flags['NAVIGATION_OUTCOME_HQ'];
  const dove = useUiFlag('ui_dove_activity_page');
  const doveAllowed = dove.isReady && dove.enabled;
  const permissionsAllowed =
    featureflags.isReady &&
    featureflags.flags['NAVIGATION_OUTCOME_HQ_PERMISSIONS'];
  const brandInsights = useUiFlag('ui_brand_insights2');
  const measurementInsights = useUiFlag('ui_measurement_insights');
  const measurementInsightsAllowed =
    measurementInsights.isReady && measurementInsights.enabled;
  const navigate = useNavigate();

  if (me && user && user.sub) {
    boot({
      name: user?.name,
      email: user?.email,
      userId: user?.sub,
      userHash: me.intercom,
    });

    trackEvent('Login', {
      ...me,
    });
  }

  useQuery<WhereToGoQuery>(whereToGoQuery, {
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      const whereToGo = data.whereToGo.__typename;
      if (whereToGo === 'GoToOnboarding') {
        navigate('/onboarding');
      }
    },
    onError() {
      if (user?.email_verified) {
        navigate(paths.HOME);
      } else {
        navigate(paths.VERIFYEMAIL);
      }
    },
  });

  return (
    <AuthRequired>
      <Suspense fallback={<FallbackPageLoad />}>
        <Routes>
          <Route path={paths.HOME} element={<Home />} />
          <Route path={paths.MEASUREMENT} element={<Measurement />} />

          {/* Have to add a '*' below to allow for tab routes to work */}
          <Route
            path={`${paths.MEASUREMENT_DASHBOARD}/*`}
            element={
              measurementInsightsAllowed ? (
                <MeasurementInsightsDashboard />
              ) : (
                <MeasurementDashboard />
              )
            }
          />

          <Route path={paths.INSIGHTS} element={<Insights />} />
          <Route
            path={paths.INSIGHTS_BRANDS}
            element={
              brandInsights.isReady && brandInsights.enabled ? (
                <InsightsBrands />
              ) : (
                <Home />
              )
            }
          />
          <Route
            path={paths.INSIGHTS_MERCHANTS}
            element={
              brandInsights.isReady && brandInsights.enabled ? (
                <InsightsMerchants />
              ) : (
                <Home />
              )
            }
          />
          <Route path={paths.INSIGHTS_RFM} element={<RFM />} />
          <Route path={paths.INSIGHTS_SWITCHING} element={<Switching />} />
          <Route path={paths.INSIGHTS_CONQUESTING} element={<Conquesting />} />
          <Route path={paths.INSIGHTS_AFFINITIES} element={<Affinities />} />
          <Route
            path={paths.INSIGHTS_CONSUMER_PROFILE}
            element={<ConsumerProfile />}
          />
          <Route
            path={paths.INSIGHTS_CROSS_PURCHASE}
            element={<CrossPurchase />}
          />

          {/* Have to add a '*' below to allow for tab routes to work */}
          <Route path={`${paths.AUDIENCES}/*`} element={<Audiences />} />

          <Route path={paths.AUDIENCES_BUILD} element={<BuildAudience />} />
          <Route
            path={paths.AUDIENCES_BUILD_EXISTING}
            element={<BuildAudience />}
          />
          <Route
            path={paths.OUTCOME_CAMPAIGNS}
            element={outcomeAllowed ? <Outcome /> : <Home />}
          />
          <Route
            path={paths.OUTCOME_CAMPAIGN}
            element={outcomeAllowed ? <Campaign /> : <Home />}
          />
          <Route
            path={`${paths.OUTCOME_PERMISSIONS}/*`}
            element={
              outcomeAllowed && permissionsAllowed ? <Permissions /> : <Home />
            }
          />
          <Route path={paths.ONBOARDING} element={<Onboarding />} />
          <Route path="/403" element={<_403 />} />
          <Route path="/404" element={<_404 />} />
          <Route path={paths.LOGIN} element={<Navigate to={paths.HOME} />} />
          <Route path={paths.LOGIN_REDIRECT} element={<LoginRedirect />} />
          <Route path={paths.VERIFYEMAIL} element={<VerifyEmail />} />
          <Route
            path={paths.MAP}
            element={doveAllowed ? <MapDove /> : <Map />}
          />
          <Route path="*" element={<_404 />} />
        </Routes>
      </Suspense>
    </AuthRequired>
  );
};

export default InternalApp;
