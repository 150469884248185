import styled from 'styled-components';

import { theme } from '@klover/attain-design-system';

export const Index = styled.div`
  display: flex;
  gap: ${theme.spacing.sm};
`;

export const CellPositive = styled.div`
  ${theme.typography.bodyMD};
  align-items: center;
  background: ${theme.colors.successBackground};
  border-radius: ${theme.spacing.xxs};
  color: ${theme.colors.textSecondary};
  display: flex;
  justify-content: center;
  text-align: center;
  width: 84px;

  svg {
    color: ${theme.colors.successForeground};
    margin-right: ${theme.spacing.xxs};
  }
`;

export const CellNegative = styled(CellPositive)`
  background: ${theme.colors.errorBackground};

  svg {
    color: ${theme.colors.errorForeground};
  }
`;

export const CellNA = styled(CellPositive)`
  background: ${theme.colors.backgroundLight};

  svg {
    color: ${theme.colors.buttonTextDisabled};
  }
`;
