export const AutocompleteNoOptionsText = 'No results';

export const campaignPartnerEnumDisplayMap = {
  NEXXEN: 'Partner campaign | Nexxen',
  OPEN_X: 'Partner campaign | OpenX',
  PIXEL: 'Pixel campaign',
  TTD: 'Partner campaign | The Trade Desk',
  YAHOO: 'Partner campaign | Yahoo',
  GENERIC: 'Campaign',
};

export const campaignTypeDisplayMap = {
  NexxenCampaign: 'Partner campaign | Nexxen',
  OpenXCampaign: 'Partner campaign | OpenX',
  PixelCampaign: 'Pixel campaign',
  TtdCampaign: 'Partner campaign | The Trade Desk',
  YahooCampaign: 'Partner campaign | Yahoo',
  GenericCampaign: 'Campaign',
};
