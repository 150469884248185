import { Share } from '@phosphor-icons/react';
import { theme } from '@klover/attain-design-system';
import * as Styled from './MenuItemExport.styles';

export const MenuItemExport = ({ data, ...props }) => {
  return (
    <Styled.Wrapper>
      <Styled.Button data={data} {...props}>
        <Share size={theme.icons.lg} />
        Export CSV
      </Styled.Button>
    </Styled.Wrapper>
  );
};

export default MenuItemExport;
