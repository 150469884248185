import React from 'react';
import paths from 'constants/paths';
import useFeatureFlags from 'hooks/useFeatureFlags/useFeatureFlags';
import {
  Binoculars,
  Buildings,
  CaretDown,
  CaretRight,
  ChartLineUp,
  House,
  UsersThree,
} from '@phosphor-icons/react';
import { theme } from '@klover/attain-design-system';
import { useGlobalState } from 'state';
import * as Styled from './NavBar.styles';

const NavBar = () => {
  const [state, actions] = useGlobalState();
  const featureflags = useFeatureFlags();

  const [isOhqMenuOpen, setIsOhqMenuOpen] = React.useState(
    window.location.href.includes(paths.OUTCOME)
  );

  const toggleOhqMenu = () => {
    setIsOhqMenuOpen(!isOhqMenuOpen);
  };

  return (
    <Styled.Wrapper $open={state.showNav}>
      <Styled.List>
        <li>
          <Styled.ListLink
            to={paths.HOME}
            data-intercom-target="mainnav-dashboard"
            onClick={() => {
              setIsOhqMenuOpen(false);
              actions.hideNav();
            }}
          >
            <House size={theme.icons.lg} weight="bold" />
            <Styled.Label>Home</Styled.Label>
          </Styled.ListLink>
        </li>

        <li>
          <Styled.ListLink
            to={paths.AUDIENCES}
            data-intercom-target="mainnav-activation"
            onClick={() => {
              setIsOhqMenuOpen(false);
              actions.hideNav();
            }}
          >
            <UsersThree size={theme.icons.lg} weight="bold" />
            <Styled.Label>Activation</Styled.Label>
          </Styled.ListLink>
        </li>

        <li>
          <Styled.ListLink
            to={paths.INSIGHTS}
            data-intercom-target="mainnav-insights"
            onClick={() => {
              setIsOhqMenuOpen(false);
              actions.hideNav();
            }}
          >
            <Binoculars size={theme.icons.lg} weight="bold" />
            <Styled.Label>Insights</Styled.Label>
          </Styled.ListLink>
        </li>

        <li>
          <Styled.ListLink
            to={paths.MEASUREMENT}
            data-intercom-target="mainnav-measurement"
            onClick={() => {
              setIsOhqMenuOpen(false);
              actions.hideNav();
            }}
          >
            <ChartLineUp size={theme.icons.lg} weight="bold" />
            <Styled.Label>Measurement</Styled.Label>
          </Styled.ListLink>
        </li>

        {featureflags.isReady && featureflags.flags['NAVIGATION_OUTCOME_HQ'] && (
          <li>
            <Styled.ListLink
              to={paths.OUTCOME_CAMPAIGNS}
              onClick={() => {
                toggleOhqMenu();
              }}
            >
              <Buildings size={theme.icons.lg} weight="bold" />
              <Styled.Label>AttainMT</Styled.Label>
              {isOhqMenuOpen ? <CaretDown /> : <CaretRight />}
            </Styled.ListLink>

            {isOhqMenuOpen && (
              <Styled.SubList>
                <li>
                  <Styled.SubListLink
                    to={paths.OUTCOME_CAMPAIGNS}
                    onClick={() => actions.hideNav()}
                  >
                    <Styled.Label>Campaigns</Styled.Label>
                  </Styled.SubListLink>
                </li>

                {featureflags.isReady &&
                  featureflags.flags['NAVIGATION_OUTCOME_HQ_PERMISSIONS'] && (
                    <li>
                      <Styled.SubListLink
                        to={paths.OUTCOME_PERMISSIONS}
                        onClick={() => actions.hideNav()}
                      >
                        <Styled.Label>Permissions</Styled.Label>
                      </Styled.SubListLink>
                    </li>
                  )}
              </Styled.SubList>
            )}
          </li>
        )}
      </Styled.List>
    </Styled.Wrapper>
  );
};

export default NavBar;
