import BreakdownRow from './BreakdownRow/BreakdownRow';
import GhostLoader from 'react-ghost-loader';
import IndexScore from './IndexScore/IndexScore';
import MetricBar100 from 'components/MetricBar100/MetricBar100';
import OverviewAudienceParticipation from './OverviewAudienceParticipation/OverviewAudienceParticipation';
import OverviewIndexScore from './OverviewIndexScore/OverviewIndexScore';
import React from 'react';
import YearOverYear from './YearOverYear/YearOverYear';
import useUiFlag from 'hooks/useFeatureFlags/useUiFlag';
import { CaretLeft, CaretRight, Image } from '@phosphor-icons/react';
import {
  SmartPersonaCategoryBreakdownTable,
  SmartPersonaEnrichedIndex,
  SmartPersonaEnrichedTable,
  SmartPersonaPfm1CategoryBreakdown,
  SmartPersonaPfm2CategoryBreakdown,
  SmartPersonaPfm2MerchantBreakdown,
  SmartPersonaPurchasesQuery,
} from 'types/graphql/generated';
import { hasValidValue } from 'utils/hasValidValue';
import { theme } from '@klover/attain-design-system';
import * as Styled from './Tab.styles';
import * as StyledCommon from '../SmartPersona.styles';

interface Props {
  data?: SmartPersonaPurchasesQuery;
  loading: boolean;
  scrollDrawerToTop?: () => any;
}

const PurchaseTab = ({ data, loading, scrollDrawerToTop }: Props) => {
  const offlineOnline = data?.smartPersonaPurchases
    .data[0] as SmartPersonaEnrichedTable;
  const thirdParty = data?.smartPersonaPurchases
    .data[1] as SmartPersonaEnrichedTable;
  const breakdown = data?.smartPersonaPurchases
    .data[2] as SmartPersonaCategoryBreakdownTable;
  const [breakdownLevel1, setBreakdownLevel1] =
    React.useState<SmartPersonaPfm1CategoryBreakdown | null>();
  const [breakdownLevel2, setBreakdownLevel2] =
    React.useState<SmartPersonaPfm2CategoryBreakdown | null>();
  const [breakdownLevel3, setBreakdownLevel3] =
    React.useState<SmartPersonaPfm2MerchantBreakdown | null>();
  const [activeSlide, setActiveSlide] = React.useState<number>(0);
  const [thirdPartyCollapsed, setThirdPartyCollapsed] =
    React.useState<boolean>(true);

  const censusBalancing = useUiFlag('ui_census_balancing');
  const allowCB = censusBalancing.isReady && censusBalancing.enabled;

  const hasAudiencePercentage =
    data && hasValidValue(data, 'audiencePercentage');

  return !loading && data ? (
    <Styled.Slider $activeSlide={activeSlide}>
      <Styled.Slide>
        <Styled.TabHeader>Purchase</Styled.TabHeader>

        <StyledCommon.Segment>
          <Styled.SegmentHeader>
            {offlineOnline.title}
            <Styled.TimePeriod>Over the last 12 months</Styled.TimePeriod>
          </Styled.SegmentHeader>

          <StyledCommon.IndexTable>
            <thead>
              <tr>
                <th>{offlineOnline?.categoryColumnTitle}</th>
                <th style={{ width: 1 }}>Index score</th>

                {allowCB && <th style={{ width: 1 }}>% of audience</th>}

                <th style={{ width: 1 }}>Top merchant</th>
              </tr>
            </thead>

            <tbody>
              {offlineOnline.data.map((row) => {
                return (
                  <tr key={row.id}>
                    <td>
                      <Styled.Text>
                        <Styled.Logo>
                          {row.category && row.category.logo ? (
                            <img src={row.category.logo} alt="" />
                          ) : (
                            <Image size={theme.icons.md} />
                          )}
                        </Styled.Logo>
                        {row.category && row.category.title}
                      </Styled.Text>
                    </td>

                    <td>
                      <IndexScore index={row.index} />
                    </td>

                    {allowCB && (
                      <>
                        {hasAudiencePercentage && (
                          <td>
                            <MetricBar100
                              index={row.audiencePercentage.value as number}
                            />
                          </td>
                        )}
                      </>
                    )}

                    <td>
                      <Styled.Text>
                        <Styled.Logo>
                          {row.topMerchant && row.topMerchant.logo ? (
                            <img src={row.topMerchant.logo} alt="" />
                          ) : (
                            <Image size={theme.icons.md} />
                          )}
                        </Styled.Logo>

                        {row.topMerchant.title}
                      </Styled.Text>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </StyledCommon.IndexTable>
        </StyledCommon.Segment>

        <StyledCommon.SegmentThirdParty $collapsed={thirdPartyCollapsed}>
          <Styled.SegmentHeader>{thirdParty?.title}</Styled.SegmentHeader>

          <StyledCommon.IndexTable>
            <thead>
              <tr>
                <th>{thirdParty?.categoryColumnTitle}</th>
                <th style={{ width: 1 }}>Index score</th>
                {allowCB && <th style={{ width: 1 }}>% of audience</th>}
                <th style={{ width: 1 }}>Top merchant</th>
              </tr>
            </thead>

            <tbody>
              {thirdParty?.data[0] && (
                <tr>
                  <td
                    onClick={() => {
                      setThirdPartyCollapsed(!thirdPartyCollapsed);
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    <Styled.Text>
                      <Styled.BreakdownTitleCaret
                        $collapsed={thirdPartyCollapsed}
                      >
                        <CaretRight size={theme.icons.md} />
                      </Styled.BreakdownTitleCaret>
                      {thirdParty.data[0]?.category.title}
                    </Styled.Text>
                  </td>

                  <td>
                    <IndexScore index={thirdParty.data[0].index} />
                  </td>

                  {allowCB && (
                    <>
                      {hasAudiencePercentage && (
                        <td>
                          <MetricBar100
                            index={
                              thirdParty.data[0].audiencePercentage
                                .value as number
                            }
                          />
                        </td>
                      )}
                    </>
                  )}

                  <td>
                    <Styled.Text>
                      <Styled.Logo>
                        {thirdParty.data[0].topMerchant &&
                        thirdParty.data[0].topMerchant.logo ? (
                          <img
                            src={thirdParty.data[0].topMerchant.logo}
                            alt=""
                          />
                        ) : (
                          <Image size={theme.icons.md} />
                        )}
                      </Styled.Logo>

                      {thirdParty.data[0].topMerchant.title}
                    </Styled.Text>
                  </td>
                </tr>
              )}
              <tr>
                <Styled.HighestIndexing colSpan={allowCB ? 4 : 3}>
                  Highest indexing
                </Styled.HighestIndexing>
              </tr>

              {(thirdParty.data.at(0) as SmartPersonaEnrichedIndex)?.data?.map(
                (row) => {
                  return (
                    <tr key={row.id}>
                      <td>
                        <Styled.Text>
                          <Styled.Logo>
                            {row.category && row.category.logo ? (
                              <img src={row.category.logo} alt="" />
                            ) : (
                              <Image size={theme.icons.md} />
                            )}
                          </Styled.Logo>
                          {row.category.title}
                        </Styled.Text>
                      </td>

                      <td>
                        <IndexScore index={row.index} />
                      </td>

                      {allowCB && (
                        <>
                          {hasAudiencePercentage && (
                            <td>
                              <MetricBar100
                                index={row.audiencePercentage.value as number}
                              />
                            </td>
                          )}
                        </>
                      )}

                      <td>
                        <Styled.Text>
                          <Styled.Logo>
                            {row.topMerchant && row.topMerchant.logo ? (
                              <img src={row.topMerchant.logo} alt="" />
                            ) : (
                              <Image size={theme.icons.md} />
                            )}
                          </Styled.Logo>

                          {row.topMerchant.title}
                        </Styled.Text>
                      </td>
                    </tr>
                  );
                }
              )}
            </tbody>
          </StyledCommon.IndexTable>
        </StyledCommon.SegmentThirdParty>

        <StyledCommon.Segment>
          <Styled.SegmentHeader>Category breakdown</Styled.SegmentHeader>

          <StyledCommon.IndexTable>
            <thead>
              <tr>
                <th>Category</th>
                <th style={{ width: 1 }}>Index score</th>

                <th style={{ width: 1 }}>
                  {censusBalancing.isReady &&
                    censusBalancing.enabled &&
                    '% of audience'}
                </th>

                <th style={{ width: 1 }}></th>
              </tr>
            </thead>

            <tbody>
              {breakdown.data.map((row) => {
                return (
                  <BreakdownRow
                    key={row.id}
                    row={row}
                    drillDown={() => {
                      setBreakdownLevel1(row);
                      setActiveSlide(1);
                      if (scrollDrawerToTop) {
                        scrollDrawerToTop();
                      }
                    }}
                  />
                );
              })}
            </tbody>
          </StyledCommon.IndexTable>
        </StyledCommon.Segment>
      </Styled.Slide>

      {breakdownLevel1 && (
        <Styled.Slide>
          <Styled.CategoryHeader
            onClick={() => {
              setActiveSlide(0);
            }}
          >
            <CaretLeft size={theme.icons.lg} />
            {breakdownLevel1.entity.title}
          </Styled.CategoryHeader>

          <OverviewIndexScore index={breakdownLevel1.index} />

          {allowCB && (
            <>
              {hasAudiencePercentage && (
                <OverviewAudienceParticipation
                  index={breakdownLevel1.audiencePercentage}
                />
              )}
            </>
          )}

          <Styled.YoY>
            <YearOverYear
              title="Avg Total Spend"
              amount={breakdownLevel1.averageSpend}
            />
            <YearOverYear
              title="Avg Spend/Transaction"
              amount={breakdownLevel1.averageSpendPerTransaction}
            />
            <YearOverYear
              title="Avg Frequency"
              amount={breakdownLevel1.averageFrequency}
            />
          </Styled.YoY>

          <StyledCommon.Segment>
            <Styled.SegmentHeader>
              {breakdownLevel1.entity.title}
            </Styled.SegmentHeader>

            <StyledCommon.IndexTable>
              <thead>
                <tr>
                  <th>Category</th>
                  <th style={{ width: 1 }}>Index score</th>

                  <th style={{ width: 1 }}>
                    {censusBalancing.isReady &&
                      censusBalancing.enabled &&
                      '% of audience'}
                  </th>

                  <th style={{ width: 1 }}></th>
                </tr>
              </thead>

              <tbody>
                {breakdownLevel1.pfm2Categories.map((row) => {
                  return (
                    <BreakdownRow
                      key={row.id}
                      row={row}
                      drillDown={() => {
                        setBreakdownLevel2(row);
                        setActiveSlide(2);
                        if (scrollDrawerToTop) {
                          scrollDrawerToTop();
                        }
                      }}
                    />
                  );
                })}
              </tbody>
            </StyledCommon.IndexTable>
          </StyledCommon.Segment>
        </Styled.Slide>
      )}

      {breakdownLevel2 && (
        <Styled.Slide>
          <Styled.CategoryHeader>
            <CaretLeft
              size={theme.icons.lg}
              onClick={() => {
                setActiveSlide(1);
              }}
            />
            {breakdownLevel2.entity.title}
          </Styled.CategoryHeader>

          <OverviewIndexScore index={breakdownLevel2.index} />

          {allowCB && (
            <>
              {hasAudiencePercentage && (
                <OverviewAudienceParticipation
                  index={breakdownLevel2.audiencePercentage}
                />
              )}
            </>
          )}

          <Styled.YoY>
            <YearOverYear
              title="Avg Total Spend"
              amount={breakdownLevel2.averageSpend}
            />
            <YearOverYear
              title="Avg Spend/Transaction"
              amount={breakdownLevel2.averageSpendPerTransaction}
            />
            <YearOverYear
              title="Avg Frequency"
              amount={breakdownLevel2.averageFrequency}
            />
          </Styled.YoY>

          <StyledCommon.Segment>
            <Styled.SegmentHeader>
              {breakdownLevel2.entity.title}
            </Styled.SegmentHeader>

            <StyledCommon.IndexTable>
              <thead>
                <tr>
                  <th>Merchant</th>
                  <th style={{ width: 1 }}>Index score</th>

                  <th style={{ width: 1 }}>
                    {censusBalancing.isReady &&
                      censusBalancing.enabled &&
                      '% of audience'}
                  </th>

                  <th style={{ width: 1 }}></th>
                </tr>
              </thead>

              <tbody>
                {breakdownLevel2.merchants.map((row) => {
                  return (
                    <BreakdownRow
                      key={row.id}
                      row={row}
                      drillDown={() => {
                        setBreakdownLevel3(row);
                        setActiveSlide(3);
                        if (scrollDrawerToTop) {
                          scrollDrawerToTop();
                        }
                      }}
                    />
                  );
                })}
              </tbody>
            </StyledCommon.IndexTable>
          </StyledCommon.Segment>
        </Styled.Slide>
      )}

      {breakdownLevel3 && (
        <Styled.Slide>
          <Styled.CategoryHeader>
            <CaretLeft
              size={theme.icons.lg}
              onClick={() => {
                setActiveSlide(2);
              }}
            />
            <Styled.Logo>
              {breakdownLevel3.entity.logo ? (
                <img src={breakdownLevel3.entity.logo} alt="" />
              ) : (
                <Image size={theme.icons.md} />
              )}
            </Styled.Logo>
            {breakdownLevel3.entity.title}
          </Styled.CategoryHeader>

          <OverviewIndexScore index={breakdownLevel3.index} />

          {allowCB && (
            <>
              {hasAudiencePercentage && (
                <OverviewAudienceParticipation
                  index={breakdownLevel3.audiencePercentage}
                />
              )}
            </>
          )}

          <Styled.YoY>
            <YearOverYear
              title="Avg Total Spend"
              amount={breakdownLevel3.averageSpend}
            />
            <YearOverYear
              title="Avg Spend/Transaction"
              amount={breakdownLevel3.averageSpendPerTransaction}
            />
            <YearOverYear
              title="Avg Frequency"
              amount={breakdownLevel3.averageFrequency}
            />
          </Styled.YoY>
        </Styled.Slide>
      )}
    </Styled.Slider>
  ) : (
    <>
      {[...Array(5)].map((_, i) => (
        <StyledCommon.Loader key={i}>
          <GhostLoader
            height="24px"
            heightRandom={0}
            width="24px"
            widthRandom={0}
          />
          <GhostLoader height="32px" width="40%" heightRandom={0} />
        </StyledCommon.Loader>
      ))}
    </>
  );
};

export default PurchaseTab;
