import styled from 'styled-components';

import { theme } from '@klover/attain-design-system';

export const Wrapper = styled.div`
  aspect-ratio: 5/3;
  position: relative;

  & > div {
    position: absolute;
  }
`;

export const LeftAxis = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 72px);
  justify-content: space-between;
  left: 0;
  position: absolute;
  top: 3px;
  width: 6px;

  > span {
    border-radius: 50%;
    display: block;
    height: 6px;
    width: 6px;
  }
`;

export const GridLines = styled(LeftAxis)`
  width: 100%;
  padding: 3px 69px 1px 69px;

  > span {
    background: ${theme.colors.borderDark};
    display: block;
    border-radius: 50%;
    opacity: 0.3;
    height: 2px;
    width: 100%;
  }
`;

export const RightAxis = styled(LeftAxis)`
  left: auto;
  right: 0;

  /* > span {
    position: relative;
  } */
`;

interface AxisProps {
  value: string;
}

export const axisRightItem = styled.span<AxisProps>`
  position: relative;
  background: ${theme.colors.CHART_TERTIARY};
  ::before {
    position: absolute;
    right: 24px;
    bottom: -5px;
    ${theme.typography.bodyMD}
    color: ${theme.colors.textTertiary};
    content: '${({ value }) => value}';
  }
`;

export const axisLeftItem = styled(axisRightItem)<AxisProps>`
  position: relative;
  background: ${theme.colors.CHART_PRIMARY};
  ::before {
    left: 24px;
  }
`;

export const BottomAxis = styled.div`
  ${theme.typography.bodyMD};
  bottom: 37px;
  color: #9591a3;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  width: calc(100% - 136px);
`;

export const Tooltip = styled.div`
  ${theme.typography.bodySM};
  box-shadow: ${theme.mixins.shadowLight};
  background: ${theme.colors.backgroundPrimary};
  border-radius: ${theme.spacing.xxs};
  padding: ${theme.spacing.xs} ${theme.spacing.md};
`;

export const Label = styled.span`
  ${theme.typography.label};
`;

export const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: ${theme.spacing.xxs};
  margin-block: ${theme.spacing.xxs};
`;
