import Back from './BackButton/BackButton';
import {
  Control,
  Controller,
  FieldValues,
  UseFormHandleSubmit,
} from 'react-hook-form';
import { InputLabel, TextField } from '@klover/attain-design-system';
import * as Styled from './Step.styles';

interface Props {
  step: number;
  prevPage: () => void;
  handleSubmit: UseFormHandleSubmit<FieldValues>;
  onSubmit: (data: FieldValues) => void;
  validEmails: boolean;
  control: Control;
  loading: boolean;
}

export const InviteTeamStep = ({
  step,
  prevPage,
  handleSubmit,
  onSubmit,
  validEmails,
  control,
  loading,
}: Props) => {
  return (
    <Styled.Block>
      <Back prevPage={prevPage} />

      <Styled.Step>Step {step} / 6</Styled.Step>

      <Styled.Header>Invite your team!</Styled.Header>

      <Styled.InputWrapper fullWidth>
        <InputLabel id="teamEmails-label" required>
          Emails
        </InputLabel>
        <Controller
          name="teamEmails"
          control={control}
          render={({ field: { onChange, onBlur, value, name, ref } }) => (
            <TextField
              aria-labelledby="teamEmails-label"
              id="teamEmails"
              fullWidth
              placeholder="Enter emails (comma separated)"
              onChange={onChange}
              onBlur={onBlur}
              value={value ?? ''}
              name={name}
              inputRef={ref}
              error={!validEmails}
              helperText={!validEmails ? 'Invalid email address' : null}
            />
          )}
        />
      </Styled.InputWrapper>

      <Styled.Submit
        loading={loading}
        variant="contained"
        fullWidth
        onClick={handleSubmit(onSubmit)}
      >
        Launch Attain
      </Styled.Submit>
    </Styled.Block>
  );
};

export default InviteTeamStep;
