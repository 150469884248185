import { MeasurementFormInterface } from 'hooks/useMeasurementForm/useMeasurementForm';
import { format } from 'date-fns';
import { locationEnum } from 'views/Measurement/MeasurementInsightsDashboard';

export type AppliedFilter = {
  title: string;
  value: string;
};

type uiFlags = {
  allowImpute: boolean;
};

export const getAppliedFilters = (
  location: locationEnum,
  values: MeasurementFormInterface,
  uiFlags?: uiFlags
) => {
  const appliedFilters: AppliedFilter[] = [];
  if (location !== locationEnum.INCREMENTALITY) {
    appliedFilters.push({
      title: 'Attribution window',
      value: values.window?.title ?? '',
    });

    if (values.dateRange?.from && values.dateRange?.to) {
      const isSameYear =
        values.dateRange.from.getFullYear() ===
        values.dateRange.to.getFullYear();
      appliedFilters.push({
        title: 'Date range',
        value: `${format(
          values.dateRange.from,
          isSameYear ? 'MMM dd' : 'MMM dd, yyyy'
        )} - ${format(values.dateRange.to, 'MMM dd, yyyy')}`,
      });
    }
  }

  if (location === locationEnum.NEWBUYERLIFT) {
    appliedFilters.push({
      title: 'Net new buyer window',
      value: values.newCustomerWindow?.title ?? '',
    });
  }

  switch (location) {
    case locationEnum.INCREMENTALITY:
      appliedFilters.push({
        title: 'Post period',
        value: values.postPeriod?.toString() + ' days',
      }); // incrementality currently has no filters
      if (uiFlags?.allowImpute) {
        appliedFilters.push({
          title: 'Impute',
          value: values.useImpute ? 'On' : 'Off',
        });
      }
      break;
  }
  return appliedFilters;
};
export default getAppliedFilters;
