import DialogInvitePeople from 'components/DialogInvitePeople/DialogInvitePeople';
import InsightsSearchCard from 'views/InsightsNew/InsightsSearchCard/InsightsSearchCard';
import LookupTool from 'components/IdentityLookupTool/LookupTool';
import OutcomeHQLogo from 'assets/new-outcome-hq-logo.svg';
import React from 'react';
import paths from 'constants/paths';
import useMe from 'hooks/useMe/useMe';
import useUiFlag from 'hooks/useFeatureFlags/useUiFlag';
import {
  Avatar,
  IconButton,
  Menu,
  MenuItem,
  theme,
} from '@klover/attain-design-system';
import { Chats, List, UserCirclePlus, X } from '@phosphor-icons/react';
import { InvitedFrom } from 'types/graphql/generated';
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useGlobalState } from 'state';
import { useIntercom } from 'react-use-intercom';
import { useMediaQuery } from 'react-responsive';
import * as Styled from './GlobalHeader.styles';

const GlobalHeader = () => {
  const me = useMe();
  const { logout } = useAuth0();
  const { show, shutdown } = useIntercom();
  const [brandInsightsOpen, setBrandInsightsOpen] = React.useState(false);
  const [isInviteOpen, setIsInviteOpen] = React.useState(false);
  const brandInsights = useUiFlag('ui_brand_insights2');
  const isDesktop = useMediaQuery({ query: theme.mq.tabletPortraitUp });
  const allowBrandInsights = brandInsights.isReady && brandInsights.enabled;
  const [state, actions] = useGlobalState();

  const identityLookup = useUiFlag('ui_identity_assistant');
  const identityLookupEnabled =
    identityLookup.isReady && identityLookup.enabled;

  const handleLookupClick = () => {
    handleClose();
    actions.setDrawerContents(
      <LookupTool onClose={() => actions.hideDrawer()} />
    );
  };

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-Menu' : undefined;

  return (
    <Styled.Wrapper>
      <Link to={paths.HOME}>
        <Styled.Logo src={OutcomeHQLogo} />
      </Link>

      <Styled.Actions>
        <IconButton
          onClick={() => {
            setIsInviteOpen(true);
          }}
        >
          <UserCirclePlus size={theme.icons.lg} />
        </IconButton>

        <IconButton
          onClick={() => {
            show();
          }}
        >
          <Chats size={theme.icons.lg} />
        </IconButton>

        <span
          role="button"
          aria-describedby={id}
          onClick={(e) => handleClick(e)}
        >
          <Avatar>
            {me?.firstName?.charAt(0)}
            {me?.lastName?.charAt(0)}
          </Avatar>
        </span>

        <Menu
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          {identityLookupEnabled && (
            <MenuItem onClick={handleLookupClick}>Lookup</MenuItem>
          )}
          <MenuItem
            onClick={() => {
              shutdown();
              logout({
                logoutParams: {
                  returnTo: `${window.origin}${paths.LOGIN}`,
                },
              });
            }}
          >
            Logout
          </MenuItem>
        </Menu>

        {!isDesktop && (
          <>
            {state.showNav ? (
              <IconButton onClick={() => actions.hideNav()}>
                <X />
              </IconButton>
            ) : (
              <IconButton onClick={() => actions.showNav()}>
                <List />
              </IconButton>
            )}
          </>
        )}
      </Styled.Actions>

      <DialogInvitePeople
        isOpen={isInviteOpen}
        onClose={() => {
          setIsInviteOpen(false);
        }}
        InviteLocation={InvitedFrom.MAIN_NAV}
      />

      {allowBrandInsights && (
        <InsightsSearchCard
          title="Brand Insights"
          open={brandInsightsOpen}
          onClose={() => {
            setBrandInsightsOpen(false);
          }}
        />
      )}
    </Styled.Wrapper>
  );
};

export default GlobalHeader;
