import styled from 'styled-components';

import { InputLabel, theme } from '@klover/attain-design-system';

export const SnapshotWrapper = styled.div`
  ${theme.mixins.stack()};

  @media ${theme.mq.tabletPortraitUp} {
    min-width: 400px;
  }
`;

export const Label = styled(InputLabel)`
  ${theme.typography.label}
`;
