import { createGlobalStyle } from 'styled-components';
import { theme } from '@klover/attain-design-system';

const GlobalStyle = createGlobalStyle`
  body {
    background: ${theme.colors.backgroundLight} !important;
    font-family: 'Calibre-Regular', sans-serif;
    -webkit-font-smoothing: subpixel-antialiased;
  }

  .sb-show-main {
    background: white !important;
  }

  .adl-select__menu-portal {
    z-index: 9999999999 !important;
  }
  
  .hide_intercom {
    display: none;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }
  p {
    margin: 1em 0;
  }
  p:first-child {
    margin-top: 0;
  }
  p:last-child {
    margin-bottom: 0;
  }
  
  a {text-decoration: none !important;}

  table {
    width: 100%;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .MuiInputBase-root {
    ${theme.typography.bodyMD};
    background: ${theme.colors.backgroundPrimary};
  }

  .MuiTabs-indicator {
    display: none;
  }

  .MuiAccordion-root {
    background: none;
    box-shadow: none;
  }

  textarea {
    ${theme.typography.bodyMD};
    background: ${theme.colors.backgroundBrandLightest};
    border:1px solid #D4D8DA;
    border-radius: ${theme.spacing.xxs};
    padding: ${theme.spacing.md};
    width: 100%;
  }

  //focused input labels
  .MuiInputLabel-root.Mui-focused {
    background: #fff;
    padding-inline: ${theme.spacing.xxs};
  }

  ul {
    list-style: none;
    padding: 0;

    &:not(.MuiGrid-root) {
      margin: 0;
    }
  }

  .MuiMenu-paper {
    box-shadow: 0 1px 4px hsl(0 0% 0% / 16%) !important;
    max-height: 400px !important
  }

  .MuiPopover-paper {
    ${theme.mixins.shadowLight};
    ${theme.typography.bodyMD};
    border-radius: ${theme.spacing.xs} !important;
    max-width: 300px !important;
  }

  .MuiList-root {
    && {
      padding: 0;
    }
  }

  .MuiAutocomplete-root {
    .MuiInputBase-root {
      padding: 0 ${theme.spacing.xl} 0 0;
    }

    .MuiInputBase-input,
    .MuiAutocomplete-input {
      padding: 0 !important;
    }

    svg {
      height: 18px;
      width: 18px;
      top: 14px;
    }

    .MuiAutocomplete-endAdornment {
      right: 14px !important;
    }

    .MuiAutocomplete-popupIndicatorOpen {
      transform: none;
    }
  }
  
  .MuiInputAdornment-root {
    height: 18px;
    width: 18px;
    margin-right: 0 !important; 

    svg {
      height: 18px;
      width: 18px;
    }
  }

  //selected state of autocomplete (hack)
  .MuiAutocomplete-root:not(:has(.MuiInputBase-input[value=''])) .MuiInputBase-root {
    background: ${theme.colors.backgroundBrandLight};
    color: ${theme.colors.text};

    .MuiOutlinedInput-notchedOutline {
      border-color: ${theme.colors.buttonOutlinedTextDefault};
    }
  }  
  //selected state of autocomplete (hack)
  .MuiAutocomplete-root:has(div ~ div) .MuiInputBase-root {
    background: ${theme.colors.backgroundBrandLight};
    color: ${theme.colors.text};

    .MuiOutlinedInput-notchedOutline {
      border-color: ${theme.colors.buttonOutlinedTextDefault};
    }
  }
  //selected state of datepicker (hack)
  .MuiTextField-root:not(:has(.MuiInputBase-input[value=''])) .MuiInputBase-root {
    background: ${theme.colors.backgroundBrandLight};
    color: ${theme.colors.text};

    .MuiOutlinedInput-notchedOutline {
      border-color: ${theme.colors.buttonOutlinedTextDefault};
    }
  }
  
  .intercom-namespace {
    z-index: 0;
  }
`;

export default GlobalStyle;
