import React from 'react';
import paths from 'constants/paths';
import { Button, Dialog } from '@klover/attain-design-system';
import { TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useGlobalState } from 'state';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import * as Styled from './DialogCreateSnapshot.styles';

interface Props {
  formSubmitCallback?: (data: any) => void;
  isOpen: boolean;
  mutation: any;
  mutationInput: any;
  onClose(): void;
}

const DialogCreateSnapshotNew = ({
  formSubmitCallback,
  isOpen,
  mutation,
  mutationInput,
  onClose,
}: Props) => {
  const navigate = useNavigate();
  const [, actions] = useGlobalState();

  const { register, handleSubmit } = useForm<{
    name: string | null;
    notes: string;
  }>();

  const [submit, { loading }] = useMutation(mutation);

  const formSubmit = React.useCallback(
    (data) => {
      if (data) {
        submit({
          variables: {
            input: {
              ...mutationInput,
              title: data.name,
              description: data.notes || '',
            },
          },
        })
          .then((response) => {
            if (response.errors) {
              // Handle GraphQL errors
              console.error('GraphQL errors:', response.errors);
              actions.showToastError(
                'Error',
                'Failed to save audience. Please try again.'
              );
            } else {
              // Handle success
              actions.showToast('', 'Audience successfully saved');
              navigate(paths.AUDIENCES + paths.AUDIENCES_MY_AUDIENCE_TAB);
              if (formSubmitCallback) {
                formSubmitCallback(data);
              }
              onClose();
              actions.hideDrawer();
            }
          })
          .catch((error) => {
            // Handle network or other errors
            console.error('Network error:', error);
            actions.showToastError(
              'Error',
              'Failed to save audience. Please try again.'
            );
          });
      }
    },
    [submit, actions, onClose, navigate, formSubmitCallback, mutationInput]
  );

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      headerID="snapshotAndSave"
      header="Snapshot & Save Audience"
      bodyID="snapshotAndSaveBody"
      body={
        <Styled.SnapshotWrapper>
          <div>
            <Styled.Label htmlFor="name">Name</Styled.Label>
            <TextField
              data-test-id="snapshot-and-save-audience-name"
              id="name"
              fullWidth
              {...register('name', { required: true })}
            />
          </div>

          <div>
            <Styled.Label htmlFor="notes">Notes</Styled.Label>
            <TextField
              data-test-id="snapshot-and-save-audience-notes"
              id="notes"
              multiline
              rows={3}
              fullWidth
              placeholder="Add notes about this audience"
              {...register('notes')}
            />
          </div>
        </Styled.SnapshotWrapper>
      }
      footer={
        <Button
          variant="contained"
          onClick={handleSubmit(formSubmit)}
          loading={loading}
        >
          Snapshot & Save
        </Button>
      }
    />
  );
};

export default DialogCreateSnapshotNew;
