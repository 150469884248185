import { ArrowDown, ArrowUp, Minus } from '@phosphor-icons/react';
import { IndexValue, IndexValueEnum } from 'types/graphql/generated';
import { theme } from '@klover/attain-design-system';
import * as Styled from './IndexScore.styles';

interface Props {
  index: IndexValue;
}

const IndexScore = ({ index }: Props) => {
  if (index && index.kind) {
    return (
      <Styled.Index>
        {index.kind === IndexValueEnum.HIGH && (
          <Styled.CellPositive>
            <ArrowUp size={theme.icons.md} weight="bold" />
            {index.description}
          </Styled.CellPositive>
        )}
        {index.kind === IndexValueEnum.LOW && (
          <Styled.CellNegative>
            <ArrowDown size={theme.icons.md} weight="bold" />
            {index.description}
          </Styled.CellNegative>
        )}
        {(index.kind === IndexValueEnum.NEUTRAL ||
          index.kind === IndexValueEnum.NA) && (
          <Styled.CellNA>
            <Minus size={theme.icons.md} weight="bold" />
            {index.description}
          </Styled.CellNA>
        )}

        {Math.round(index.value)}
      </Styled.Index>
    );
  } else {
    return (
      <Styled.Index>
        <Styled.CellNegative>Error</Styled.CellNegative>
      </Styled.Index>
    );
  }
};

export default IndexScore;
